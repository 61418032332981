.bodyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.formContainer {
  width: 90%;
  height: 90%;
  max-width: 600px;
  max-height: 500px;
  display: flex;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.mainText {
  text-align: center;
  margin-bottom: 50px !important;
  font-size: 2rem !important;
  font-weight: 500 !important;
}

.inputText {
  width: 245.77px;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}