body {
  background-color: #f0f0f0;
  background-image: url(../images/audioVisInverted2.jpg);
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
}

a {
  text-decoration: none;
  color: inherit;
}

.textIcon {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 17px;
}

.linkHover {
  transition: color 0.6s;
}

.linkHover:hover {
  color: #d91226;
}

.linkHover:focus {
  color: #d91226;
}

.audioPlayer {
  background-color: white;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
}

.profileAudioPlayer,
.mobileAudioPlayer {
  background-color: white;
  border-radius: 4px;
  width: 100%;
}

audio.react-audio-player.profileAudioPlayer,
audio.react-audio-player.mobileAudioPlayer {
  height: 30.75px;
}

audio.react-audio-player.mobileAudioPlayer::-webkit-media-controls-mute-button,
audio.react-audio-player.mobileAudioPlayer::-webkit-media-controls-volume-slider,
audio.react-audio-player.mobileAudioPlayer::-webkit-media-controls-timeline {
  display: none;
}

.commentForm {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* Chips */

.chip .MuiChip-avatarColorSecondary {
  background-color: transparent !important;
  width: 20px !important;
  height: 20px !important;
}

/* Show Comments Icon */
.commentIcon:hover {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

/* Add Comment Icon */
.addIcon {
  animation: animate 2s ease infinite;
}

@keyframes shake {
  70% {
    transform: translate3d(-1px, 0, 0);
  }
  
  10%, 65% {
    transform: translate3d(2px, 0, 0);
  }

  20%, 40%, 60% {
    transform: translate3d(-4px, 0, 0);
  }
}


@keyframes animate {
  0%{
    fill: #e8e8e8;
  }
  25%{
    fill: #d91226;
  }
  50%{
    fill: #e8e8e8;
  }
  75%{
    fill: #d91226;
  }
  100%{
    fill: #e8e8e8;
  }
}