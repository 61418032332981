.postContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 24px;
  background-color: #1f1f1f !important;
  width: 100%;
  max-width: 2000px;
}

.postSections {
  display: flex;
  flex-direction: column;
}

.postMain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.picAndText {
  display: flex;
  width: 100%;
}

.pfp {
  width: 80px !important;
  height: 80px;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.textContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.title {
  width: 100%;
  word-break: break-all;
  color: #e8e8e8;
}

.userColor {
  color: #919191;
}

.playerAndButtons {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.checkboxesContainer {
  display: flex;
  width: 100%;
  height: 53.99px;
}

.leftCheckbox {
  width: 100%;
  height: 100%;
  padding-right: 5px;
}